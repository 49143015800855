<template>
  <div class="login">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TheLogin'
}
</script>

<style lang="scss">
  .login{
    padding: 2rem;
    background-color: colors("background");
    border-radius: 0.4rem;
    border-left: 6px solid colors(modules);

    .title{
      padding-bottom: 0.4rem;
      margin-top: 0;
      margin-bottom: 2rem;
      font-size: 2.2rem;
      font-weight: font-weights("normal");
      border-bottom: 1px solid colors(border);
    }

    button {
      @include background-color(colors(primary));
      width: 100%;
      padding: 0.7rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      border-radius: 0.4rem;
      border: none;
      color: colors("background");
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: font-weights(bold);
    }

    .form-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;

      label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;
        font-weight: font-weights(normal);
        margin-right: 0;

        .icon {
          margin-right: 0.7rem;
          color: colors(tertiary);
        }

        a {
          display: inline;
          margin-left: auto;
          font-size: 1.4rem;
        }
      }

      input {
        border-radius: 0.4rem;

        &.error {
          border: 1px solid red;
          background-color: tint(colors("error"), 90%);
        }
      }
    }

    .error {
      color: red;
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }
  }
</style>
