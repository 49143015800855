<template>
  <div class="login-new-password">
    <BaseTitle tag="h2">Enter your new password below</BaseTitle>
    <div class="login-new-password__body">
      <form @submit.prevent.stop="submitPassword" ref="formNewPassword" novalidate>

        <!-- Email -->

        <ElementFormGroup :label="'Email'">
          <input
            :id="'email'"
            :name="'email'"
            :type="'email'"
            :class="{'error': errors.has('email')}"
            ref="email"
            v-validate="'required|email'"
            v-model="email"
            data-vv-validate-on="none">
          <span class="error" v-if="errors.has('email')">
            {{ errors.first('email') }}
          </span>
        </ElementFormGroup>

        <!-- New Password -->

        <ElementFormGroup :label="'New Password'">
          <input
            :id="'password'"
            :name="'password'"
            :type="'password'"
            :class="{'error': errors.has('password')}"
            ref="password"
            v-validate="'required|min:5|max:128'"
            v-model="password"
            data-vv-validate-on="none">
          <span class="error" v-if="errors.has('password')">
            {{ errors.first('password') }}
          </span>
        </ElementFormGroup>

        <!-- Confirm Password -->

        <ElementFormGroup :label="'Confirm Password'">
          <input
            :id="'password_confirmation'"
            :name="'password_confirmation'"
            :type="'password'"
            :class="{'error': errors.has('password_confirmation')}"
            v-validate="'required|confirmed:password'"
            v-model="passwordConfirmation"
            data-vv-validate-on="none">
          <span class="error" v-if="errors.first('password_confirmation')">
            {{ errors.first('password_confirmation') }}
          </span>
        </ElementFormGroup>

        <BaseCheckbox
          v-if="firstTime == 'true'"
          :guid="'gdpr_optin'"
          :value="gdprOptin ? 'true' : 'false'"
          :label="$t('message.login.gdpr')"
          :active="gdprOptin"
          data-vv-name="gdpr_optin"
          class=""
          v-validate="firstTime == 'true' ? 'required:true' : ''"
          @change="handleGdprOptin"
        />
          <span class="error" v-if="firstTime == 'true' && errors.first('gdpr_optin')">
            {{ errors.first('gdpr_optin') }}
          </span>

        <button type="submit">Submit</button>

      </form>
    </div>
    <div class="login-new-password__info" v-if="errorMessage">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseTitle from '@base/BaseTitle'
import ElementFormGroup from '@elements/ElementFormGroup'
import BaseCheckbox from '@base/BaseCheckbox'

export default {
  name: 'TheLoginNewPassword',
  props: {
    token: {
      type: String,
      required: true
    },
    userEmail: {
      type: String,
      required: true
    },
    firstTime: {
      type: String,
      required: false,
      default: 'false'
    }
  },
  components: {
    BaseTitle,
    ElementFormGroup,
    BaseCheckbox
  },
  data () {
    return {
      email: '',
      password: '',
      passwordConfirmation: '',
      gdprOptin: false,
      error: false
    }
  },
  mounted () {
    this.email = this.userEmail
  },
  computed: {
    ...mapGetters({
      errorMessage: 'auth/getErrorMessage'
    }),
    errorClass () {
      return { 'error': this.error }
    }
  },
  methods: {
    ...mapActions({
      requestNewPassword: 'auth/requestNewPassword',
      updateErrorMessage: 'auth/updateErrorMessage'
    }),
    handleGdprOptin ({ active }) {
      this.gdprOptin = active
    },
    submitPassword () {
      if (!this.isSubmitting) {
        this.$validator.validateAll().then(result => {
          this.error = !result
          if (result) {
            this.updateErrorMessage('')
            this.requestNewPassword({
              token: this.token,
              email: this.email,
              password: this.password,
              passwordConfirmation: this.passwordConfirmation,
              gdprOptin: this.gdprOptin
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.login-new-password {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  // Title

  .title{
    order: 1;
    width: 100%;
    flex: 0 1 auto;
  }

  // Body

  &__body{
    order: 2;
    width: 100%;
    flex: 0 1 auto;
  }

  // Info

  &__info {
    @include form-info-error;
    order: 0;
  }

  .checkbox {
    margin-bottom: 1.5rem;

    &__label{
      align-items: flex-start;
      font-weight: normal;
    }

    .badge__text{
      font-size: 1.2rem;
    }
  }
}
</style>
