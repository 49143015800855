<template>
  <div class="new-password-page">
    <ElementBrand />
    <TheLogin>
      <TheLoginNewPassword :token="token" :firstTime="firstTime" :userEmail="email" />
    </TheLogin>
  </div>
</template>

<script>
import ElementBrand from '@elements/ElementBrand'
import TheLogin from '@blocks/login/TheLogin'
import TheLoginNewPassword from '@blocks/login/TheLoginNewPassword'

export default {
  name: 'NewPasswordView',
  props: {
    token: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    firstTime: {
      type: String,
      required: false,
      default: 'false'
    }
  },
  components: {
    ElementBrand,
    TheLogin,
    TheLoginNewPassword
  }
}
</script>

<style lang="scss">
.new-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6.5rem;
  background-color: colors("foreground");
  height: 100vh;

  .brand{
    margin-bottom: 4rem;
  }

  .login{
    @include media("small-medium"){
      width: 90%;
    }
    @include media("large"){
      width: 44rem;
    }
    min-height: 32.3rem;
  }
}

</style>
